/*! @design/bapf-pattern-library v3.6.0 | 11.04.2024 | _design-tokens.scss */
////
/// Design Tokens
/// Variablen zur Umsetzung des Styleguides
/// @group settings
////
@forward 'colors';
@use 'colors' as *;
@use 'helpers' as *;
@use 'bootstrap/scss/functions.scss';

// ---------------- Farben (abstrakt/nach Verwendungszweck) ----------------

$dt-color-text: $color-blue-dark; // Standard Textfarbe / Vordergrundfarbe
$dt-color-text-secondary: $color-grey-darker; // Sekundäre Textfarbe, für z.B. Labels und Microcopy
$dt-color-text-inverted: $color-white; // Textfarbe auf dunklen Hintergründen

$dt-color-marker-hover: $color-blue-dark; // Marker-Farbe für hover, z.B. in Dropdowns und Buttongroup
$dt-color-marker-active: $color-red; // Marker-Farbe für active, z.B. in Dropdowns und Buttongroup

$dt-color-border-light: rgba($color-blue-dark, .16); // heller Rahmen, z.B. für Trennstriche
$dt-color-border-medium: $color-grey-darker; // mittelheller Rahmen, z.B. für Formularfelder
$dt-color-border-dark: $color-blue-dark; // dunkler Rahmen, z.B. für fokusierte Formularfelder

$dt-color-error: $color-red-error; // Rot für Fehlerauszeichnungen
$dt-color-warning: $color-yellow; // Gelb für Warnungsauszeichnungen
$dt-color-success: $color-green; // Grün für Erfolgsauszeichnungen

$dt-bg-body: $color-white-dark; // Hintergrundfarbe <body> / Hauptseitenbereich
$dt-bg-container: $color-white; // Hintergrundfarbe hervorgehobene Container
$dt-bg-hover: rgba($color-blue-dark, .08); // Hintergrundfarbe Block-Element hover
$dt-bg-active: rgba($color-blue-dark, .16); // Hintergrundfarbe Block-Element active (Mouse down)


// ---------------- Standardeigenschaften ----------------

$dt-border-radius: 5px; // Standard-Radius für abgerundete Ecken von Block-Elementen
$dt-spacer: 24px; // Standardabstand, Basis für alle Abstände (z.B. Block-Paddings, zwischen Elementen, Grid-Padding, ...)

// Animations-/Transition-Zeiten
$dt-transition-fast: .08s; // schnelle Animation (interaktiv, sofort, z.B. hover, click, ...)
$dt-transition-slow: .24s; // langsame Animation (nach Interaktion, verzögert, z.B. Icon-Animation, collapse, Menus, ...)

// Standardabstand nach unten
@mixin dt-bottom-margin($factor: 1) {
  margin-bottom: calc-rem($dt-spacer * $factor);
}

// Standardabstand nach oben
@mixin dt-top-margin($factor: 1) {
  margin-top: calc-rem($dt-spacer * $factor);
}

// Standardabstand nach innen
@mixin dt-padded($factor: 1) {
  padding: $dt-spacer * $factor;
}

// Abgerundete Container
@mixin dt-rounded($borderRadius: $dt-border-radius) {
  border-radius: $borderRadius;
}

// Silbentrennung
@mixin dt-hyphenation() {
  word-break: break-word;
  hyphens: auto;
}


// ---------------- Hintergrundfarbe für transparente Grafiken im Hochkontrastmodus ----------------
// Parameter:
// $bg-theme: light|dark: hellen (weiß)/dunklen (schwarz) Hintergrund einfügen
// $bg-custom-color: optional: Hintergrundfarbe für Hochkontrastmodus für Browser mit forced-color-adjust Unterstützung
@mixin dt-contrast-bg($bg-theme: light, $bg-custom-color: none) {
  $bg-dark: ButtonText;
  $bg-light: Canvas;
  $bg-color: #FFFFFF;
  @if $bg-theme == dark {
    $bg-dark: Canvas;
    $bg-light: ButtonText;
    $bg-color: #000000;
  }
  @if $bg-custom-color != none {
    $bg-color: $bg-custom-color;
  }
  $bg-color-encoded: functions.str-replace(quote(#{$bg-color}), '#', '%23');
  $bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='10000' height='10000' style='fill:[bg];'/%3E%3C/svg%3E");

  // Lösung für moderne Browser
  @supports (forced-color-adjust: none) {
    @media (forced-colors: active) {
      forced-color-adjust: none;
      background: $bg-color !important; // stylelint-disable declaration-no-important
    }
  }

  // Lösung für ältere Browser
  @supports not (forced-color-adjust: none) {
    // Webkit-Lösung
    // Webkit setzt prefers-color-scheme auch mit dem gewählten Hochkontrast-Farbschema, unterstützt Schema-Farbnamen, aber kein background-image im Hochkontrast-Modus
    @media (forced-colors: active) and (prefers-color-scheme: dark) {
      background: $bg-dark !important;
    }
    @media (forced-colors: active) and (prefers-color-scheme: light) {
      background: $bg-light !important;
    }
  }

  // Mozilla-Lösung
  // Mozilla unterstützt Hintergrundbilder im Hochkontrastmodus, wenn sie über url() eingebunden sind.
  // Da Mozilla prefers-color-scheme nicht aus den Hochkontrast-Modi lesen kann, muss hier mit einem Hintergrundbild gearbeitet werden.
  @supports (-moz-appearance: none) {
    @media (forced-colors: active) {
      background: functions.str-replace($bg-image, '[bg]', $bg-color-encoded) !important;
    }
  }
}


// ---------------- Milchglas-Overlay mit abgerundeten Ecken ----------------
@mixin dt-blurred-overlay($saturation: 100%, $blur: 10px, $borderRadius: 3px, $backgroundColor: rgba(255, 255, 255, 0.8)) {
  backdrop-filter: saturate($saturation) blur($blur);
  border-radius: $borderRadius;
  background-color: $backgroundColor;
}


// ---------------- Schatten für materialistische Elemente ----------------

// -------- CSS box-shadow --------
$shadows: (
  e1: '0 2px 4px 0 rgba(0,0,0,0.16), 0 4px 24px 0 rgba(0,0,0,0.08)',        // Erhebungslevel 1
  e2: '0 5px 7px 0 rgba(0,0,0,0.20), 0 4px 24px 0 rgba(0,0,0,0.08)',        // Erhebungslevel 2
  e3: '0 12px 10px -2px rgba(0,0,0,0.08), 0 12px 24px 4px rgba(0,0,0,0.08)' // Erhebungslevel 3
);

// Variablen
$dt-shadow-e1: unquote(map-get($shadows, e1));
$dt-shadow-e2: unquote(map-get($shadows, e2));
$dt-shadow-e3: unquote(map-get($shadows, e3));

// Mixin
// Parameter:
// $elevation: Erhebungslevel
// $extraShadow (optional): zusätzlicher Schatten für Kombination, z.B. Erhebungslevel + focus-Rahmen
@mixin dt-shadow($elevation: e1, $extraShadow: null) {
  box-shadow: join(unquote(map-get($shadows, $elevation)), $extraShadow);
}

// -------- CSS filter: drop-shadow() --------
$drop-shadows: (
  e1: '0 2px 3px rgba(0,0,0,0.20)',  // Erhebungslevel 1
  e2: '0 5px 4px rgba(0,0,0,0.24)',  // Erhebungslevel 2
  e3: '0 12px 8px rgba(0,0,0,0.14)'  // Erhebungslevel 3
);

// Variablen
$dt-drop-shadow-e1: unquote(map-get($drop-shadows, e1));
$dt-drop-shadow-e2: unquote(map-get($drop-shadows, e2));
$dt-drop-shadow-e3: unquote(map-get($drop-shadows, e3));

// Mixin
// Parameter:
// $elevation: Erhebungslevel
@mixin dt-drop-shadow($elevation: e1) {
  filter: drop-shadow(unquote(map-get($drop-shadows, $elevation)));
}


// ---------------- Tastaturfocus ----------------
$dt-focus-frame: 1px 1px 0 1px $dt-bg-body,
  1px -1px 0 1px $dt-bg-body,
  -1px 1px 0 1px $dt-bg-body,
  -1px -1px 0 1px $dt-bg-body,
  0 0 0 2px $dt-bg-body,
  0 0 0 4px $dt-color-border-dark;

@mixin dt-focus-frame {
  outline: 2px solid transparent;
  box-shadow: $dt-focus-frame;
}


// ---------------- animierter Underline-Marker für interaktive Elemente ----------------

// Kann als Pseudo-Element an verschiedene interaktive Elemente angehängt werden
// Parameter:
// $color: Farbe des Markers im Hover-Zustand, default $color-red-dark
// $bgColor: Hintergrundfarbe des Markers im Normalzustand, default 'transparent'
// $pseudo: Pseudo-Element, das benutzt werden soll, 'before' oder 'after', default 'after'
// $attachTo (optional): Kind-Element, an das der Effekt gehängt werden soll
@mixin dt-hover-marker($color: $color-red, $bgColor: 'transparent', $pseudo: 'after', $attachTo: '') {
  &#{$attachTo}::#{$pseudo} {
    display: block;
    height: 2px;
    transition: background-size $dt-transition-fast ease-in-out, background-position $dt-transition-fast ease-in-out;
    border-radius: 1px;
    background: #{$bgColor} linear-gradient(to bottom, transparent, transparent 2px, #{$color}) no-repeat center top;
    background-size: 50% 20px;
    content: '';
  }
  &:not(.active):hover,
  &:not(.active):focus,
  &:not(.active):focus-within {
    &#{$attachTo}::#{$pseudo} {
      outline: 1px solid transparent;
      outline-offset: -1px;
      background-position: center bottom;
      background-size: 100% 20px;
    }
  }
}

// ---------------- Übergang (transition) mit Standard-Timing und easing ----------------

// Parameter
// $prop (Properties): kann mehrere Attribute haben, getrennt durch Leerzeichen, z.B. color background-color
// $speed (Übergangsdauer, optional): default ist $dt-transition-fast: .08s
// Beispielausgabe: transition: color .08s ease-in-out, background-color .08s ease-in-out;
@mixin dt-transition($prop, $speed: $dt-transition-fast) {
  $transition: ();
  @for $i from 1 through length($prop) {
    $value: (nth($prop, $i) 2s ease-in-out);
    $transition: append(
      $transition, (nth($prop, $i) $speed ease-in-out),
      $separator: comma
    );
  }
  transition: $transition;
}

// ---------------- Animation des Chevrons für Collapsibles ----------------
@mixin dt-animate-expand-chevron() {
  &::after {
    transition: transform $dt-transition-slow;
  }

  &[aria-expanded=true] {
    &::after {
      transform: rotateX(180deg);
    }
  }
}

// ---------------- Microcopy, Zusatztexte, usw. ----------------
@mixin dt-microcopy {
  color: $dt-color-text-secondary;
  font-size: calc-rem(13px);
  line-height: 1.2;
  a {
    font-size: calc-rem(13px);
    line-height: 1.2;
  }
}


