/*! @design/bapf-pattern-library v3.6.0 | 11.04.2024 | _helpers.scss */
////
/// Helpers
/// Global genutzte Mixins und andere Hilfskonstrukte
/// @group settings
////

@use 'sass:math'; //NOSONAR benötigt für Dart-Sass Division

// rem-Berechnung (zu 16px Standardgröße)
@function calc-rem($px-size, $base: 16px) {
  $rem-size: math.div($px-size, $base);
  @return $rem-size * 1rem;
}

// Seitenverhältnis beibehalten
@mixin aspect-ratio($width, $height, $inner:'.ba-ratio-content') {
  position: relative;
  &::before {
    display: block;
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
    //padding-top: $height / $width * 100%;
    content: '';
  }
  > #{$inner} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

// media query kompakter Header bis Tablet-Portrait-Breite oder Landscape bei niedriger Höhe (Smartphones quer)
@mixin media-header-compact() {
  @media  (width <= 767px) and (orientation: portrait),
          (width <= 991px) and (orientation: landscape) {
    @content;
  }
}

// media query großer Header ab Tablet-Portrait-Breite oder Landscape bei ausreichender Höhe und Breite
@mixin media-header-full() {
  @media  (width >= 768px) and (orientation: portrait),
          (width >= 992px) and (orientation: landscape) {
    @content;
  }
}

// media query Hochkontrastmodus
@mixin media-high-contrast() {
  @media  (-ms-high-contrast: active),
          (forced-colors: active),
          (prefers-contrast: more) {
    @content;
  }
}

// ----------- DEPRECATED: DPL2 Breakpoint mixins mapping auf DPL3 Bootstrap mixins

// phone
@mixin phone {
  @include media-breakpoint-only(xs) { // ---- == phone ----
    @content;
  }
}

@mixin phone-min {
  @media (width >= 0) {
    @content;
  }
}

@mixin phone-max {
  @include media-breakpoint-only(xs) { // ---- == phone ----
    @content;
  }
}


// phone landscape
@mixin phablet {
  @include media-breakpoint-only(sm) { // ---- == phone landscape ----
    @content;
  }
}

@mixin phablet-min {
  @include media-breakpoint-up(sm) { // ---- >= phone landscape ----
    @content;
  }
}

@mixin phablet-max {
  @include media-breakpoint-down(sm) { // ---- < phone landscape ----
    @content;
  }
}

// tablet
@mixin tablet {
  @include media-breakpoint-only(md) { // ---- == tablet ----
    @content;
  }
}

@mixin tablet-min {
  @include media-breakpoint-up(md) { // ---- >= tablet ----
    @content;
  }
}

@mixin tablet-max {
  @include media-breakpoint-down(md) { // ---- < tablet ----
    @content;
  }
}

// desktop
@mixin desktop {
  @include media-breakpoint-only(lg) { // ---- == desktop ----
    @content;
  }
}

@mixin desktop-min {
  @include media-breakpoint-up(lg) { // ---- >= desktop ----
    @content;
  }
}

@mixin desktop-max {
  @include media-breakpoint-down(lg) { // ---- < desktop ----
    @content;
  }
}

// large desktop
@mixin large-desktop {
  @include media-breakpoint-only(xl) { // ---- == large desktop ----
    @content;
  }
}

@mixin large-desktop-min {
  @include media-breakpoint-up(xl) { // ---- >= large desktop ----
    @content;
  }
}

@mixin large-desktop-max {
  @include media-breakpoint-down(xl) { // ---- < large desktop ----
    @content;
  }
}
