//  *******************************************************************************************************************
//     _             _ _                             _       _
//    | |           | (_)                           (_)     | |
// ___| |_ _   _  __| |_ ___ _   _ ______ _ __  _ __ _ _ __ | |_
/// __| __| | | |/ _` | / __| | | |______| '_ \| '__| | '_ \| __|
//\__ \ |_| |_| | (_| | \__ \ |_| |      | |_) | |  | | | | | |_
//|___/\__|\__,_|\__,_|_|___/\__,_|      | .__/|_|  |_|_| |_|\__|
//                                       | |
//                                       |_|
//  print style - baby
//  CSS Overrides (trumps) für Druckansicht Styles.
//  *******************************************************************************************************************

// hier sind print-spezifische Styles
@media print {
  @mixin print-col-md-4 {
    width: 33%;
  }

  @mixin print-col-md-8 {
    width: 66%;
  }

  @mixin print-col-md-6 {
    width: 50%;
  }

  @mixin print-col-lg-8 {
    width: 66%;
  }
  @mixin print-col-lg-4 {
    width: 33%;
  }

  .panel-open {
    margin-bottom: 2rem;
  }

  .ba-btn {
    display: none;
  }

  .ba-input-checkbox-text-print {
    font-weight: normal;
  }

  //trumps for studienangebot component
  ._studienangebot_ {
    //make info (Schwerpunkte) to md-8
    .col-lg-8 {
      float: left;
      @include print-col-md-8;
    }
    //make info right (anbieter, studienort, bundesland) to md-4
    .col-lg-12 {
      float: left;
      @include print-col-md-4;
    }
  }

  @mixin studienangebote-count-print {
    .studienangebote-count-print {
      @include print-col-md-4;
      display: inline-block;
      border: solid 3px black;
      text-align: center;
      padding: 1rem;
      width: 50%;
    }
  }

  div.studisu-content-studienfeldinfo {
    .text-center {
      text-align: center;
      margin-top: -3rem;
      @include studienangebote-count-print;
    }
    .text-right {
      text-align: right;
      @include studienangebote-count-print;
    }
  }
}
