/*! @design/bapf-pattern-library v3.6.0 | 11.04.2024 | _colors.scss */
////
/// Farben
/// Liste alle zur Verfügung stehenden Farben
/// @group settings
////


// blau
$color-blue-light:        #D9E1ED;
$color-blue:              #305C73;
$color-blue-dark:         #020E25;
$color-blue-darkest:      $color-blue-dark;
$color-blue-link:         #3D4657;

// grau
$color-white:             #FFFFFF;
$color-white-dark:        #F9FBFC;
$color-grey-lightest:     $color-blue-light;
$color-grey-lighter:      $color-white-dark; // deprecated, war #EDEDED, wird in einer der nächsten Versionen entfernt
$color-grey-light:        #DDE3E8;
$color-grey:              #B3B3B3;
$color-grey-dark:         #838383;
$color-grey-darker:       #595959;
$color-grey-darkest:      #2B2B2B;

// rot
$color-red-light:         #FDC4C4;
$color-red:               #B30920;
$color-red-dark:          #660011;
$color-red-ba:            #E2001A;
$color-red-error:         #E50000;

// orange
$color-orange-light:      #FFDEBD;
$color-orange:            #FF8500;
$color-orange-dark:       #663300;

// grün
$color-green-light:       #E2F8E2;
$color-green:             #1F8904;
$color-green-dark:        #0A2F04;

// gelb
$color-yellow-light:      #F9F6BE;
$color-yellow:            #FFE900;
$color-yellow-dark:       #5C5300;

// transparent
$color-transparent:       transparent;

