/*! @design/bapf-pattern-library v3.6.0 | 11.04.2024 | _variables.scss */
@forward 'design-tokens';
@forward 'helpers';
@forward 'dpl-variables';
@use 'design-tokens' as *;
@use 'helpers' as *;

// Overwrites von Bootstrap- und Custom-Variablen
@use 'dpl-variables' as *;

// Ganz zum Schluss, damit die overwrites von Bootstrap-Variablen greifen
@import 'bootstrap/scss/maps.scss';
@import 'bootstrap/scss/mixins.scss';
@import 'bootstrap/scss/utilities.scss';

