@use 'node_modules/@infosysbub/ng-lib-dpl3/src/lib/red-ghost-btn' as *;
@use 'node_modules/@design/bapf-pattern-library/source/sass/settings/variables' as *;

@use 'studisu-icon-font';
@use 'studisu-print';

@include red-ghost-btn();

// Vernünftiger BOLD-Schnitt von Roboto, den die DPL derzeit leider nicht liefert.
// latin
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url('assets/fonts/roboto-bold.woff2') format('woff2'),
    url('assets/fonts/roboto-bold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Farb-Util-Klassen

input[type='text'] {
  color: $color-blue-darkest;
}

.alert-info {
  color: $color-blue-dark;
}
