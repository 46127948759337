//Generated siehe Doku: /studisu-icon-font/readme.md
@font-face {
  font-family: 'STUDISU-Icons';
  src: url('assets/fonts/STUDISU-Icons.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/STUDISU-Icons.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/STUDISU-Icons.woff2') format('woff2'),
    /* Super Modern Browsers */ url('assets/fonts/STUDISU-Icons.woff') format('woff'),
    url('assets/fonts/STUDISU-Icons.ttf') format('truetype'),
    url('assets/fonts/STUDISU-Icons.svg') format('svg');
}

$webfont-icons: (
  'anbieter': (
    'STUDISU-Icons' 'f101'
  ),
  'anfrage': (
    'STUDISU-Icons' 'f102'
  ),
  'block': (
    'STUDISU-Icons' 'f103'
  ),
  'bookmark': (
    'STUDISU-Icons' 'f104'
  ),
  'datenherkunft': (
    'STUDISU-Icons' 'f105'
  ),
  'diploma-full': (
    'STUDISU-Icons' 'f106'
  ),
  'duales-studium': (
    'STUDISU-Icons' 'f107'
  ),
  'eignungstest': (
    'STUDISU-Icons' 'f108'
  ),
  'fernstudium': (
    'STUDISU-Icons' 'f109'
  ),
  'grundständig': (
    'STUDISU-Icons' 'f10a'
  ),
  'hochschulart-dark': (
    'STUDISU-Icons' 'f10b'
  ),
  'hochschulart': (
    'STUDISU-Icons' 'f10c'
  ),
  'progress': (
    'STUDISU-Icons' 'f10d'
  ),
  'region-full': (
    'STUDISU-Icons' 'f10e'
  ),
  'selbststudium': (
    'STUDISU-Icons' 'f10f'
  ),
  'studienfach': (
    'STUDISU-Icons' 'f110'
  ),
  'studienfelder': (
    'STUDISU-Icons' 'f111'
  ),
  'studiengangsinfo': (
    'STUDISU-Icons' 'f112'
  ),
  'studieninhalte': (
    'STUDISU-Icons' 'f113'
  ),
  'studientyp': (
    'STUDISU-Icons' 'f114'
  ),
  'teilzeitstudium': (
    'STUDISU-Icons' 'f115'
  ),
  'veroeffentlichungsinfos': (
    'STUDISU-Icons' 'f116'
  ),
  'vollzeitstudium': (
    'STUDISU-Icons' 'f117'
  ),
  'weiterführend': (
    'STUDISU-Icons' 'f118'
  ),
  'wochenendveranstaltung': (
    'STUDISU-Icons' 'f119'
  ),
  'zugangsinfo': (
    'STUDISU-Icons' 'f11a'
  )
);

.studisu-icon-anbieter::before,
.studisu-icon-anbieter-before::before,
.studisu-icon-anbieter-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f101';
}

.studisu-icon-anfrage::before,
.studisu-icon-anfrage-before::before,
.studisu-icon-anfrage-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f102';
}

.studisu-icon-block::before,
.studisu-icon-block-before::before,
.studisu-icon-block-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f103';
}

.studisu-icon-bookmark::before,
.studisu-icon-bookmark-before::before,
.studisu-icon-bookmark-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f104';
}

.studisu-icon-datenherkunft::before,
.studisu-icon-datenherkunft-before::before,
.studisu-icon-datenherkunft-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f105';
}

.studisu-icon-diploma-full::before,
.studisu-icon-diploma-full-before::before,
.studisu-icon-diploma-full-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f106';
}

.studisu-icon-duales-studium::before,
.studisu-icon-duales-studium-before::before,
.studisu-icon-duales-studium-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f107';
}

.studisu-icon-eignungstest::before,
.studisu-icon-eignungstest-before::before,
.studisu-icon-eignungstest-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f108';
}

.studisu-icon-fernstudium::before,
.studisu-icon-fernstudium-before::before,
.studisu-icon-fernstudium-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f109';
}

.studisu-icon-grundständig::before,
.studisu-icon-grundständig-before::before,
.studisu-icon-grundständig-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f10a';
}

.studisu-icon-hochschulart-dark::before,
.studisu-icon-hochschulart-dark-before::before,
.studisu-icon-hochschulart-dark-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f10b';
}

.studisu-icon-hochschulart::before,
.studisu-icon-hochschulart-before::before,
.studisu-icon-hochschulart-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f10c';
}

.studisu-icon-progress::before,
.studisu-icon-progress-before::before,
.studisu-icon-progress-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f10d';
}

.studisu-icon-region-full::before,
.studisu-icon-region-full-before::before,
.studisu-icon-region-full-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f10e';
}

.studisu-icon-selbststudium::before,
.studisu-icon-selbststudium-before::before,
.studisu-icon-selbststudium-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f10f';
}

.studisu-icon-studienfach::before,
.studisu-icon-studienfach-before::before,
.studisu-icon-studienfach-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f110';
}

.studisu-icon-studienfelder::before,
.studisu-icon-studienfelder-before::before,
.studisu-icon-studienfelder-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f111';
}

.studisu-icon-studiengangsinfo::before,
.studisu-icon-studiengangsinfo-before::before,
.studisu-icon-studiengangsinfo-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f112';
}

.studisu-icon-studieninhalte::before,
.studisu-icon-studieninhalte-before::before,
.studisu-icon-studieninhalte-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f113';
}

.studisu-icon-studientyp::before,
.studisu-icon-studientyp-before::before,
.studisu-icon-studientyp-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f114';
}

.studisu-icon-teilzeitstudium::before,
.studisu-icon-teilzeitstudium-before::before,
.studisu-icon-teilzeitstudium-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f115';
}

.studisu-icon-veroeffentlichungsinfos::before,
.studisu-icon-veroeffentlichungsinfos-before::before,
.studisu-icon-veroeffentlichungsinfos-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f116';
}

.studisu-icon-vollzeitstudium::before,
.studisu-icon-vollzeitstudium-before::before,
.studisu-icon-vollzeitstudium-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f117';
}

.studisu-icon-weiterführend::before,
.studisu-icon-weiterführend-before::before,
.studisu-icon-weiterführend-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f118';
}

.studisu-icon-wochenendveranstaltung::before,
.studisu-icon-wochenendveranstaltung-before::before,
.studisu-icon-wochenendveranstaltung-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f119';
}

.studisu-icon-zugangsinfo::before,
.studisu-icon-zugangsinfo-before::before,
.studisu-icon-zugangsinfo-after::after {
  font-family: 'STUDISU-Icons', sans-serif !important;
  content: '\f11a';
}
