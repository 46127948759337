@use '@design/bapf-pattern-library/source/sass/settings/colors' as *;

@mixin red-ghost-btn {
  .ba-btn.ba-btn-ghost.ba-btn-red-ghost:not(:disabled) {
    color: $color-red;
    background: transparent;
    &:not(.ba-btn-right):not(.dropdown-toggle):not(.ba-btn-block):not(.ba-loading):not(
        .ba-btn-next
      ) {
      &::after {
        background-image: linear-gradient(to bottom, transparent, transparent 2px, $color-red-dark);
      }
      &:hover,
      &:focus {
        color: $color-red-dark;
      }
    }
  }

  .ba-btn.ba-btn-ghost.ba-btn-red-ghost.ba-btn-block {
    &:not(:disabled) span::after {
      background-image: linear-gradient(to bottom, transparent, transparent 2px, $color-red-dark);
    }
    &:hover,
    &:focus {
      color: $color-red-dark;
    }
  }

  .ba-btn-icon-only:before {
    font-size: 1.25em;
  }
}
